import React from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import kzFlag from "./flags/kz.svg"
import ruFlag from "./flags/ru.svg"
import kgFlag from "./flags/kg.svg"
import usFlag from "./flags/en.svg"

const languageOptions = [
  {
    value: "kz",
    label: (
      <div className="flex items-center">
        <img src={kzFlag} alt="Kazakh" className="w-6 h-4 mr-2 rounded-sm" />
        Қазақша
      </div>
    ),
  },
  {
    value: "ru",
    label: (
      <div className="flex items-center">
        <img src={ruFlag} alt="Russian" className="w-6 h-4 mr-2 rounded-sm" />
        Русский
      </div>
    ),
  },
  {
    value: "kg",
    label: (
      <div className="flex items-center">
        <img src={kgFlag} alt="Kyrgyz" className="w-6 h-4 mr-2 rounded-sm" />
        Киргизча
      </div>
    ),
  },
  {
    value: "en",
    label: (
      <div className="flex items-center">
        <img src={usFlag} alt="English" className="w-6 h-4 mr-2 rounded-sm" />
        English
      </div>
    ),
  },
]

export function LanguageSwitcher() {
  const { i18n } = useTranslation()

  const handleChange = (selectedOption) => {
    i18n.changeLanguage(selectedOption.value)
  }

  return (
    <div className="w-44">
      <Select
        options={languageOptions}
        defaultValue={languageOptions.find(
          (option) => option.value === i18n.language
        )}
        onChange={handleChange}
        isSearchable={false}
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: "8px",
            border: "1px solid #fff",
            boxShadow: "none",
            backgroundColor: "rgba(255,255,255,0.3)",
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: "8px",
            overflow: "hidden",
            backgroundColor: "rgba(255,255,255,0.3)",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: "rgba(255,255,255,0.3)",
            color: "#000",
            "&:hover": {
              backgroundColor: "rgba(255,255,255,0.6)",
            },
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "#fff", // Same color as the border
            "&:hover": {
              color: "#fff", // Ensure it remains the same on hover
            },
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none", // Remove the vertical line between the select text and the dropdown arrow
          }),
        }}
      />
    </div>
  )
}
