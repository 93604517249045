// src/i18n.js

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require("./locales/en/translation.json"),
    },
    ru: {
      translation: require("./locales/ru/translation.json"),
    },
    kz: {
      translation: require("./locales/kz/translation.json"),
    },
    kg: {
      translation: require("./locales/kg/translation.json"),
    },
  },
  language: "ru", // default language
  fallbackLng: "ru",
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
})

export default i18n
