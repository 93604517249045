import React from "react"

export function Select(props) {
  return (
    <div className="grid gap-1 w-full">
      {props.label && (
        <div className="flex items-center gap-2">
          <label
            htmlFor={props.id}
            className="text-md font-semibold text-[#2f34c0]"
          >
            {props.label}
          </label>
          {props.required && <span className="text-red-500">*</span>}
        </div>
      )}

      <div className="relative w-full">
        <select
          disabled={props.disabled || false}
          onChange={(e) =>
            props.onChange && props.onChange(e.target.value ?? "unselected")
          }
          value={props.default || ""}
          id={props.id}
          className={
            "w-full border-[1.5px] p-2 rounded-lg border-[#84baf8] outline-0 focus:border-[#2f34c0] focus:shadow-md focus:shadow-[#2f34c0]/20 transition-all duration-300 " +
            props.className
          }
        >
          {props.options.map((o, index) => (
            <option key={index} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
