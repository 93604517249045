import { ViewContext } from "contexts/view"
import React, { useContext } from "react"

export function Container({ children }) {
  const { mode } = useContext(ViewContext)

  return (
    <div
      className={`min-h-screen font-sans ${mode ? "dark bg-neutral-900" : ""}`}
    >
      {children}
    </div>
  )
}
